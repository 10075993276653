<template>
    <div>
      <el-input
        type="textarea"
        :rows="6"
        placeholder="Enter JSON data"
        v-model="jsonData"
      ></el-input>  
      <el-button type="primary" @click="openEditor">Edit</el-button>
      <el-dialog :visible.sync="editorVisible" title="JSON Editor">
        <div v-for="(value, key) in editedJson" :key="key">
          <div style="width: 4em;display: inline-block;">{{key}}</div>
          <template v-if="Array.isArray(value)">
            <div v-for="(item, index) in value" :key="index">
              <el-input
                v-model="editedJson[key][index]"
                placeholder="Value"
                style="width: 40%"
              ></el-input>  
              <el-button type="danger" icon="el-icon-delete" @click="removeArrayItem(key, index)"></el-button>
            </div>
            <el-button type="primary" @click="addArrayItem(key)">添加对象</el-button>
          </template>
          <template v-else>
            <el-input
              v-model="editedJson[key]"
              placeholder="Value"
              style="width: 40%"
            ></el-input> 
            <el-button type="danger" icon="el-icon-delete" @click="removeKeyValuePair(key)"></el-button>
          </template>
        </div>
        <el-button type="primary" @click="addKeyValuePair">添加键值对</el-button>
        <el-button type="primary" @click="saveChanges">保存修改</el-button>
        <el-button @click="closeEditor">Close</el-button>
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    props: ['value'],
    data() {
      return {
        jsonData: this.value, // 初始化 JSON 数据
        editorVisible: false, // JSON 编辑器弹窗的可见性
        editedJson: {} // 编辑的 JSON 数据
      };
    },
    mounted(){
  
    },
    watch: {
      jsonData(newValue) {
        this.$emit('input', newValue); // emit the updated value to the parent component
      }
    },
    methods: {
      isObject(value) {
        return typeof value === 'object' && value !== null;
      },
      openEditor() {
        try {
          this.editedJson = JSON.parse(this.jsonData);
          this.editorVisible = true;
        } catch (error) {
          console.error('Invalid JSON data');
        }
      },
      addKeyValuePair() {
        this.$set(this.editedJson, `key${Object.keys(this.editedJson).length+1}`, `value${Object.keys(this.editedJson).length+1}`);
      },
      addArrayItem(key) {
        if (Array.isArray(this.editedJson[key])) {
          this.editedJson[key].push('');
        } else {
          this.$set(this.editedJson, key, ['']);
        }
      },
      removeKeyValuePair(key) {
        this.$delete(this.editedJson, key);
      },
      removeArrayItem(key, index) {
        this.editedJson[key].splice(index, 1);
      },
      saveChanges() {
        // Convert arrays to string before saving changes
        Object.keys(this.editedJson).forEach(key => {
          if (Array.isArray(this.editedJson[key])) {
            this.editedJson[key] = JSON.stringify(this.editedJson[key]);
          }
        });
  
        this.jsonData = JSON.stringify(this.editedJson, null, 2);
        this.editorVisible = false;
      },
      closeEditor() {
        this.editorVisible = false;
      },
      saveKeyValue(task) {
        this.$http.post("/kv/key-value", this.editedJson, true)
          .then((res) => {
            if (res.code == 200) {
  
            }
          })
          .catch((error) => {
            this.$message({
              message: error.message,
              type: "error"
            });
          });
      },
    }
  };
  </script>