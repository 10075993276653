<template>
    <div>
      <el-input
        type="textarea"
        :rows="6"
        placeholder="Enter JSON data"
        v-model="jsonData"
      ></el-input>
      <el-button type="primary" @click="Query">查询</el-button>      
      <el-button type="primary" @click="openEditor">编辑</el-button>
      <el-button type="primary" @click="Save">保存/插入</el-button>
      <el-dialog :visible.sync="editorVisible" title="JSON Editor">
        <div v-for="(value, key) in editedJson" :key="key">
            <div style="width: 4em;display: inline-block;">{{key}}</div>
            <el-input
                v-model="editedJson[key]"
                placeholder="Value"
                style="width: 40%"
            ></el-input>  
            <flexi-object v-if="indexKey===key" v-model="editedJson[key]">
                
            </flexi-object>
           
        <el-button type="primary" icon="el-icon-edit" @click="(indexKey==key)?indexKey='':indexKey=key"></el-button>
        <el-button type="danger" icon="el-icon-delete" @click="removeKeyValuePair(key)"></el-button>
      </div>
        <el-button type="primary" @click="addKeyValuePair">添加键值对</el-button>
        <el-button type="primary" @click="addArrayItem">添加对象</el-button>
        <el-button type="primary" @click="saveChanges">保存修改</el-button>
        <el-button @click="closeEditor">关闭</el-button>
      </el-dialog>
      <div ref="JSONEditor"></div>
      <div>
        <el-input style="display: inline-block;width:40px" v-model="indexQuery" @keyup.enter="transformIndex"></el-input> / {{jsonDatas.length}}
      </div>
    </div>
  </template>
  
  <script>
  import {getValue,setValue} from '../../utils/api'
  import flexiJSON from "./common/flexiJSON"
  export default {
    data() {
      return {
        jsonDatas:[],
        jsonData:`{"type": "value1","key": "","value": ""}`,
        editorVisible: false, // JSON 编辑器弹窗的可见性
        editedJson: {}, // 编辑的 JSON 数据
        indexKey:"",
        jsonEditor: null, // JSON 编辑器实例
        indexQuery:0 // 查询到的第N个
      };
    },
    watch: {
      jsonData: {
        immediate: true,
        handler(newValue, oldValue) {
          if (oldValue !== undefined) { 
            this.editedJson = JSON.parse(newValue);
            // this.jsonEditor.setText(newValue);
          }
        },
      }
    },
    components:{
        "flexi-object":flexiJSON
    },
    mounted(){
      const resourceUrls = [
        'https://cdn.bootcdn.net/ajax/libs/jsoneditor/10.1.0/jsoneditor.min.js',
        'https://cdn.bootcdn.net/ajax/libs/jsoneditor/10.1.0/jsoneditor.min.css',
      ];

      resourceUrls.forEach(url => {
        if (url.endsWith('.js')) {
          const script = document.createElement('script');
          script.src = url;
          document.head.appendChild(script);
        } else if (url.endsWith('.css')) {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = url;
          document.head.appendChild(link);
        }
      });
      this.$nextTick(() => {
        const intervalId = setInterval(() => {
        if (typeof JSONEditor !== 'undefined') {
          clearInterval(intervalId);
            this.initEditor();
          }
        }, 1000);
      })
      getValue(this.$route.query).then((res)=>{
        if(res.code == 200) {
          this.jsonDatas = res.result;
          this.transformIndex(this.indexQuery)
        } else {
          this.$message.error(res.message);
        }
      })
    },
    methods: {
      initEditor() {
        this.jsonEditor = new JSONEditor(this.$refs.JSONEditor,{
          theme: 'bootstrap2',
          schema: {
              type: "object",
              properties: {
                  name: { "type": "string" }
              }
          }
        });
        this.jsonEditor.setText(this.jsonData);
        // Validate---检查数据是否有效
        var errors = this.jsonEditor.validate();
        if(errors.length) {
          this.$message.error(errors[0].message);
        }
        this.jsonEditor._onChange = () => {
          try {
            this.jsonData = this.jsonEditor.getText();
          } catch (err) {
          
          }
        };
      },
      Query(){
        let jsonData=JSON.parse(this.jsonData);
        getValue(jsonData.id?jsonData:this.$route.query).then((res)=>{
            res.code===200?
            this.jsonData=JSON.stringify(res.result[0], null, 2)
            :this.$message.error(res.message);
        })
      },
      Save(){
        let jsonData=JSON.parse(this.jsonData);
        setValue(jsonData).then((res)=>{
            res.code===200?
            this.$message.success("添加成功")
            :this.$message.error(res.message);
        })
      },
      transformIndex(index){
        this.indexQuery = index || this.indexQuery;
        this.jsonData = JSON.stringify(this.parseObject(this.jsonDatas[this.indexQuery]))
      },
      parseObject(obj) {
        for (let key in obj) {
          if (typeof obj[key] === 'string') {
            try {
              obj[key] = JSON.parse(obj[key]);
            } catch (error) {
              // 如果解析失败，保持属性值不变
            }
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            obj[key] = parseObject(obj[key]);
          }
        }
        return obj;
      },
      isObject(value) {
          return typeof value === 'object' && value !== null;
      },
      openEditor() {
          try {
          this.editedJson = JSON.parse(this.jsonData);
          this.editorVisible = true;
          } catch (error) {
          console.error('Invalid JSON data');
          }
      },
      addKeyValuePair() {
          this.$set(this.editedJson, `key${Object.keys(this.editedJson).length+1}`, `value${Object.keys(this.editedJson).length+1}`);
      },
      addArrayItem() {
          this.$set(this.editedJson, `key${Object.keys(this.editedJson).length+1}`, []);
      },
      removeKeyValuePair(key) {
          this.$delete(this.editedJson, key);
      },
      saveChanges() {
          this.jsonData = JSON.stringify(this.editedJson, null, 2);
          this.editorVisible = false;
          console.log(this.jsonData);
          this.jsonEditor.setText(this.jsonData);
      },
      closeEditor() {
          this.editorVisible = false;
      },
      saveKeyValue(task) {
      this.$http.post("/kv/key-value", this.editedJson, true)
        .then((res) => {
          if (res.code == 200) {

          }
        })
        .catch((error) => {
          this.$message({
            message: error.message,
            type: "error"
          });
        });
    },
    }
  };
  </script>